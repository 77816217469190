export enum UserRole {
  ADMIN = 'ADMIN',
  MERCHANT = 'MERCHANT',
  EMPLOYEE = 'EMPLOYEE',
  SYSTEM = 'SYSTEM',
  SUPERADMIN = 'SUPERADMIN',
  MANAGER = 'MANAGER',
}

export interface BillingAddress {
  id?: number;
  company: string;
  first_name: string;
  last_name: string;
  street_and_housenumber: string;
  street_and_housenumber_2?: string;
  city: string;
  country: string;
  state?: string;
  post_code: number;
  email: string;
  phone?: string;
  updated_at?: string;
  created_at?: string;
}

export interface BillingAddressRequest extends BillingAddress {
  user_id: string;
}

export interface ShippingAddress {
  id?: number;
  company: string;
  first_name: string;
  last_name: string;
  street_and_housenumber: string;
  street_and_housenumber_2?: string;
  city: string;
  country: string;
  state?: string;
  post_code: number;
  phone?: string;
  updated_at?: string;
  created_at?: string;
}

export interface ShippingAddressRequest extends ShippingAddress {
  user_id: string;
}

export interface AuthUserData {
  uuid?: string;
  first_name: string;
  last_name: string;
  name?: string;
  username: string;
  email: string;
  email_verified_at?: Date;
  billing_adress?: BillingAddress;
  shipping_adress?: ShippingAddress;
  role: UserRole;
  active: number;
  parent_user_id?: number;
  store?: number;
  store_object?: UserStore;
  wp_id?: number;
  updated_at?: Date;
  created_at?: Date;
}
export interface AuthResponse {
  data: string;
  token: string;
  user: AuthUserData | undefined;
  type: string;
  expire_at: Date;
  expire: number;
}

export interface AuthUserDataRequestObject extends AuthUserData {
  user_id?: string;
}

export interface UserStore {
  id?: number;
  name: string;
  description?: string;
  users?: AuthUserData[];
  billing_address?: BillingAddress;
  shipping_address?: ShippingAddress;
}

export interface NewUser {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  store?: number | null;
  password: string;
}

/**
 * Empty object's
 */

export const emptyBillingAddress: BillingAddress = {
  id: 0,
  company: '',
  first_name: '',
  last_name: '',
  street_and_housenumber: '',
  city: '',
  country: '',
  post_code: 0,
  email: '',
};

export const emptyShippingAddress: ShippingAddress = {
  id: 0,
  company: '',
  first_name: '',
  last_name: '',
  street_and_housenumber: '',
  city: '',
  country: '',
  post_code: 0,
};

export const emptyAuthUserData: AuthUserData = {
  first_name: '',
  last_name: '',
  username: '',
  email: '',
  role: UserRole.EMPLOYEE,
  active: 1,
};

export const emptyUserStore: UserStore = {
  name: '',
  description: '',
};
